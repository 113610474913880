import { FileType } from './content.constants';

export const hasFileUrlExtensions = (extensions: string[], fileUrl: string) => {
  try {
    const { pathname } = new URL(fileUrl);
    return extensions.some((extension) => pathname.endsWith(extension));
  } catch {
    return false;
  }
};

const videoExtensions = ['.mp4', '.mov', '.ogg', '.webm'];
const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif'];

export const getFileTypeByName = (fileName: string): FileType => {
  if (videoExtensions.some((extension) => fileName.endsWith(extension))) {
    return FileType.VIDEO;
  }
  if (imageExtensions.some((extension) => fileName.endsWith(extension))) {
    return FileType.IMAGE;
  }
  return FileType.FILE;
};

export const setFileType = (fileUrl: string) => {
  if (hasFileUrlExtensions(videoExtensions, fileUrl)) {
    return FileType.VIDEO;
  }
  if (hasFileUrlExtensions(imageExtensions, fileUrl)) {
    return FileType.IMAGE;
  }
  return FileType.FILE;
};

export const isUrlFirebaseFile = (fileUrl: string) => {
  try {
    const url = new URL(fileUrl);

    return url.origin.startsWith('https://firebasestorage.googleapis.com');
  } catch {
    return false;
  }
};

export const isEmbeddedVideoUrl = (fileUrl: string): boolean => {
  const allowedEmbeddedVideoUrls = [
    'https://www.youtube.com/embed',
    'https://youtube.com/embed',
    'https://player.vimeo.com/video',
  ];

  return allowedEmbeddedVideoUrls.some((allowedEmbeddedVideoUrl) =>
    fileUrl.startsWith(allowedEmbeddedVideoUrl),
  );
};

export const humanFileSize = (size: number): string => {
  const i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  const val = (size / 1024 ** i).toFixed(2);
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i];

  return `${val} ${unit}`;
};
